import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode' // Correct named import
import './MainPage.css'
import funChessImage from './fun_chess_image.jpeg'

function MainPage() {
  const navigate = useNavigate()

  useEffect(() => {
    const fetchGoogleClientId = async () => {
      try {
        const response = await axios.get('/api/get-google-client-id-web')
        const clientId = response.data.clientId
        // Initialize Google Sign-In with the client ID from the server
        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: handleGoogleSignIn
        })
        window.google.accounts.id.renderButton(document.getElementById('google-signin-button'), {
          theme: 'outline',
          size: 'large',
          width: 250 // You can adjust this value to match the width of your other buttons
        })
      } catch (error) {
        console.error('Error fetching Google Client ID:', error)
      }
    }

    const token = localStorage.getItem('token')
    if (token) {
      const decoded = jwtDecode(token)
      if (decoded.is_new_user) {
        alert('Welcome! You have 5 free best moves, 7 free hints, and 3 free analyses.')
      }
      navigate('/upload')
    } else {
      fetchGoogleClientId()
    }
  }, [navigate])

  const handleGoogleSignIn = async response => {
    if (response.credential) {
      try {
        const result = await axios.post('/api/google-signin', {
          token: response.credential
        })
        if (result.data.token) {
          const decoded = jwtDecode(result.data.token)
          if (decoded.is_new_user) {
            alert('Welcome! You have 5 free best moves, 7 free hints, and 3 free analysis.')
          }
          localStorage.setItem('token', result.data.token)
          localStorage.setItem('email', result.data.email)
          navigate('/upload')
        }
      } catch (error) {
        if (error.response) {
          console.error('Error response:', error.response.data)
        }
      }
    } else {
      console.error('No credential received from Google Sign-In')
    }
  }

  const handleTierSelection = tier => {
    const token = localStorage.getItem('token')
    if (!token) {
      navigate(`/login?next=/checkout?tier=${tier}`)
    } else {
      navigate(`/checkout?tier=${tier}`)
    }
  }

  const tiers = [
    { name: 'Basic', price: 4.99, bestMoves: 15, hints: 20, analyses: 10, savings: '15%' },
    { name: 'Standard', price: 9.99, bestMoves: 30, hints: 45, analyses: 20, savings: '18.8%' },
    { name: 'Premium', price: 19.99, bestMoves: 60, hints: 95, analyses: 40, savings: '20.6%' }
  ]

  return (
    <div className="main-page">
      <h1>Welcome to Chess Predict!</h1>
      <h1>Best Move, Every Time.</h1>
      <div className="button-container">
        <p className="login-options">Register and Login, or use Google Sign-In</p>
        <button className="register-button" onClick={() => navigate('/register')}>
          Register
        </button>
        <button className="login-button" onClick={() => navigate('/login')}>
          Login
        </button>
        <p>OR</p>
        <div className="google-signin-container">
          <div id="google-signin-button"></div>
        </div>
      </div>
      <div className="tier-container">
        <h2>Choose a Subscription Tier:</h2>
        <div className="tier-buttons">
          {tiers.map(tier => (
            <button key={tier.name} className="tier-button" onClick={() => handleTierSelection(tier.name)}>
              <h3>{tier.name}</h3>
              <p className="price">${tier.price.toFixed(2)}/month</p>
              <ul>
                <li>{tier.bestMoves} Best Moves</li>
                <li>{tier.hints} Hints</li>
                <li>{tier.analyses} Analyses</li>
                <li>
                  <i>{tier.savings} savings over one-time purchase.</i>
                </li>
              </ul>
            </button>
          ))}
        </div>
      </div>
      <div className="chess-image">
        <img src={funChessImage} alt="Fun Chess Image" />
      </div>
      <div className="contact-container">
        <p>
          Questions?{' '}
          <a href="mailto:support@chesspredict.com" className="support-link">
            Contact support@chesspredict.com
          </a>
        </p>
      </div>
      <div className="links-container">
        <a href="/terms-of-service" className="terms-link">
          Terms of Service
        </a>
        <span> | </span>
        <a href="/privacy-policy" className="privacy-link">
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

export default MainPage
