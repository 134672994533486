import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import axios from 'axios'
import './PurchaseCredits.css'

const PurchaseCreditsForm = () => {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const [bestMovesQuantity, setBestMovesQuantity] = useState(0)
  const [hintsQuantity, setHintsQuantity] = useState(0)
  const [analysesQuantity, setAnalysesQuantity] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [error, setError] = useState('')

  const creditPrices = {
    best_moves: 0.13,
    hints: 0.12,
    analyses: 0.15
  }

  useEffect(() => {
    const calculateTotalPrice = () => {
      const total =
        bestMovesQuantity * creditPrices.best_moves +
        hintsQuantity * creditPrices.hints +
        analysesQuantity * creditPrices.analyses
      setTotalPrice(total.toFixed(2)) // To fix to 2 decimal places
    }

    calculateTotalPrice()
  }, [bestMovesQuantity, hintsQuantity, analysesQuantity])

  const handleSubmit = async event => {
    event.preventDefault()
    if (!stripe || !elements) {
      return
    }
    const cardElement = elements.getElement(CardElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })
    if (error) {
      console.error(error)
      setError('Failed to create payment method')
      return
    }
    const token = localStorage.getItem('token')
    try {
      await axios.post(
        '/api/purchase-credits',
        {
          best_moves_quantity: bestMovesQuantity,
          hints_quantity: hintsQuantity,
          analyses_quantity: analysesQuantity,
          payment_method_id: paymentMethod.id
        },
        {
          headers: { 'x-access-token': token }
        }
      )
      alert('Credits purchased successfully')
      navigate(`/upload?token=${encodeURIComponent(token)}`)
    } catch (error) {
      console.error(error)
      setError(error.response?.data?.error || 'Failed to purchase credits')
    }
  }

  return (
    <form onSubmit={handleSubmit} className="purchase-credits-form">
      <h1>Purchase Credits</h1>
      <div className="credit-selector">
        <label htmlFor="best-moves-quantity">Best Moves:</label>
        <input
          type="number"
          id="best-moves-quantity"
          value={bestMovesQuantity}
          onChange={e => setBestMovesQuantity(Number(e.target.value))}
          min="0"
        />
        <label htmlFor="hints-quantity">Hints:</label>
        <input
          type="number"
          id="hints-quantity"
          value={hintsQuantity}
          onChange={e => setHintsQuantity(Number(e.target.value))}
          min="0"
        />
        <label htmlFor="analyses-quantity">Analyses:</label>
        <input
          type="number"
          id="analyses-quantity"
          value={analysesQuantity}
          onChange={e => setAnalysesQuantity(Number(e.target.value))}
          min="0"
        />
      </div>
      <div className="total-price">
        <p>Total Price: ${totalPrice}</p>
      </div>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
      {error && <p className="error">{error}</p>}
    </form>
  )
}

const PurchaseCredits = () => {
  const [stripePromise, setStripePromise] = useState(null)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const fetchStripePublicKey = async () => {
      try {
        let token = localStorage.getItem('token')

        const extensionToken = searchParams.get('extension_token')

        if (extensionToken) {
          // If extension token is present, use it and save to localStorage
          token = extensionToken
          localStorage.setItem('token', extensionToken)
        }

        if (!token) {
          console.error('No token found')
          return
        }

        const response = await axios.get('/api/get-stripe-public-key', {
          headers: {
            'x-access-token': token
          }
        })
        const stripePublicKey = response.data.publicKey
        setStripePromise(loadStripe(stripePublicKey))
      } catch (error) {
        console.error('Error fetching Stripe public key:', error)
      }
    }

    fetchStripePublicKey()
  }, [searchParams])

  return (
    <div className="purchase-credits-page">
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <PurchaseCreditsForm />
        </Elements>
      )}
    </div>
  )
}

export default PurchaseCredits
